import { Button, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import EmptyCanvasIllustration from "assets/images/empty-states/canvas-illustration.png";
import { useAppSelector } from "store/helpers";
import { DocsPage, DOCS_PAGE_URL } from "../../constants/routes";
import { CurveArrow } from "./CurveArrow";

const LEFT_OFFSET = "12px";

const Wrapper = styled.div<{ scaleFactor: number; fade: boolean }>`
  /* The empty state shouldn't scale, so counteracts the canvas scaling. */
  transform: scale(${(props) => props.scaleFactor});
  transform-origin: top left;

  position: absolute;
  top: 0px;
  height: ${(props) => (1 / props.scaleFactor) * 100}%;
  width: calc(${(props) => (1 / props.scaleFactor) * 100}% - ${LEFT_OFFSET});
  color: ${({ theme }) => theme.colors.ACCENT_BLUE_500};
  overflow: hidden;

  /* Allow clicks thru */
  pointer-events: none;

  .curly-arrow {
    flex-shrink: 0;
  }

  .cta-wrapper {
    font-family: var(--font-family);
    position: relative;
    top: 14px;
    left: ${LEFT_OFFSET};
    display: flex;
    flex-direction: row;
    gap: 0px 50px;
    justify-content: space-between;

    .drag-and-drop-hint {
      display: flex;
      width: 450px;
      flex-shrink: 0;
      gap: 10px;
    }

    .typography {
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

      margin-top: 6px;
      max-width: 375px;
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      gap: 2px;

      h4.ant-typography {
        color: ${({ theme }) => theme.colors.GREY_500};
        margin: 0;
      }

      div.ant-typography {
        color: ${({ theme }) => theme.colors.GREY_300};
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }

      button {
        /* Ensure clicks */
        pointer-events: all;

        font-size: 16px;

        &:hover {
          color: ${({ theme }) => theme.colors.ACCENT_BLUE_700};
        }
      }
    }
  }

  .empty-canvas-illustration {
    opacity: ${(props) => (props.fade ? 0.7 : 1)};
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    display: inline-block;
    flex-shrink: 1;
    vertical-align: top;
    margin-top: 130px;
    margin-right: min(max(calc(100% - 1250px), 10px), 10%);
    overflow: hidden;

    img {
      min-width: 540px;
      max-width: 750px;
    }
  }
`;

type EmptyCanvasProps = {
  canvasScaleFactor: number;
};

export const EmptyCanvas = ({ canvasScaleFactor }: EmptyCanvasProps) => {
  const isHoverOrSelected = useAppSelector((state) => {
    return (
      state.legacy.ui.widgetDragResize.selectedWidgets.length > 0 ||
      state.legacy.ui.widgetDragResize.focusedWidgetId !== undefined
    );
  });

  return (
    <Wrapper scaleFactor={1 / canvasScaleFactor} fade={isHoverOrSelected}>
      <div className="cta-wrapper">
        <div className="drag-and-drop-hint">
          <CurveArrow startY={28} className="curly-arrow" />
          <div className="typography">
            <Typography.Title level={4}>
              Add your first component
            </Typography.Title>
            <Typography.Paragraph>
              Create the perfect user interface with Tables, Charts, Forms,
              Slideout Panels and more
            </Typography.Paragraph>

            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() =>
                window.open(
                  DOCS_PAGE_URL(DocsPage.FIVE_MIN_GUIDE_URL),
                  "_blank",
                )
              }
            >
              View 5 minute Quick Start Guide →
            </Button>
          </div>
        </div>
        <div className="empty-canvas-illustration">
          <img
            draggable={false}
            src={EmptyCanvasIllustration}
            alt="Drag and drop components"
          />
        </div>
      </div>
    </Wrapper>
  );
};
