import React from "react";

export const CurveArrow = ({ startY = 100, className = "" }) => {
  const startX = 58;
  const startLineX = startX - 10;
  startY = startY - 8;

  const endX = 0;
  const endY = 8;
  const controlPoint1X = (startLineX + endX) / 4;
  const controlPoint1Y = startY;
  const controlPoint2X = (startLineX + endX) * (3 / 4);
  const controlPoint2Y = endY;

  // Path for the S-curve
  const pathData = `M ${startLineX} ${startY} C ${controlPoint1X} ${controlPoint1Y}, ${controlPoint2X} ${controlPoint2Y}, ${endX} ${endY}`;

  // Arrowhead marker
  // should be thin lines
  const marker = (
    <marker
      id="arrowhead"
      markerWidth="4"
      markerHeight="6"
      refX="4"
      refY="3"
      orient="auto"
    >
      <line x1="0" y1="0" x2="4" y2="3" stroke="#27BBFF" strokeWidth="1" />
      <line x1="0" y1="6" x2="4" y2="3" stroke="#27BBFF" strokeWidth="1" />
    </marker>
  );

  return (
    <svg
      className={className}
      width={`${startX + 10}`}
      height={`${startY + 8}`}
      viewBox={`0 0 ${startX + 10} ${startY + 8}`}
      style={{ overflow: "visible" }}
    >
      <defs>{marker}</defs>
      <path
        d={pathData}
        stroke="#27BBFF"
        strokeWidth="1"
        fill="none"
        strokeDasharray="3,3"
        markerEnd="url(#arrowhead)"
      />
      <circle cx={startX} cy={startY} r="8" fill="#27BBFF" />
      <text
        x={startX}
        y={startY + 1}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="12"
        fontWeight="bold"
      >
        1
      </text>
    </svg>
  );
};
