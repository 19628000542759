import { PerSideBorder } from "@superblocksteam/shared/src/types/application";
import { Dimension } from "@superblocksteam/shared/src/types/page/Dimension";
import tinycolor from "tinycolor2";

const generateBoxShadowCss = (color: string): string => {
  return `0px 1px 3px 0px ${tinycolor(color).setAlpha(0.24)}`;
};

const BORDER_COLOR = "--border-color";
const BORDER_COLOR_ON_HOVER = "--border-color-on-hover";
const DEFAULT_BORDER_COLOR = "--default-border-color";
const BOX_SHADOW_ON_HOVER = "--box-shadow-on-hover";
const BORDER_TOP_WIDTH = "--border-top-width";
const BORDER_RIGHT_WIDTH = "--border-right-width";
const BORDER_BOTTOM_WIDTH = "--border-bottom-width";
const BORDER_LEFT_WIDTH = "--border-left-width";

/**
 * Generates CSS for border color, width and styles with hover states.
 *
 * Ports a simplified version of `generateBorderWidthAndColorStyleObject` for css,
 * but relies on CSS variables.
 *
 * Benefits: allows hover states, allows to be used for InputGroups
 * Drawbacks: it doesn't set each border's color and style individually.
 *
 */
export const generateCssForBorderWidthAndColorWithHover = ({
  selectors,
}: {
  selectors: string | string[];
}): string => {
  const selectorsArray = Array.isArray(selectors) ? selectors : [selectors];
  const selectorsString = selectorsArray.join(", ");
  const selectorsWithInteractionsString = selectorsArray
    .map((selector) => {
      return `${selector}:hover, ${selector}:focus, ${selector}:focus-within, ${selector}:active`;
    })
    .join(",");

  const defaultCss = `
    border-style: solid;
    border-color: var(${BORDER_COLOR});
    border-top-width: var(${BORDER_TOP_WIDTH});
    border-right-width: var(${BORDER_RIGHT_WIDTH});
    border-bottom-width: var(${BORDER_BOTTOM_WIDTH});
    border-left-width: var(${BORDER_LEFT_WIDTH});
  `;

  const onHoverCss = `
    border-color: var(${BORDER_COLOR_ON_HOVER});
    box-shadow: var(${BOX_SHADOW_ON_HOVER});
  `;

  return `${selectorsString} { ${defaultCss} } ${selectorsWithInteractionsString} { ${onHoverCss} }`;
};

export const createCssVariablesForBorderWidthAndColorWithHover = ({
  border,
  fallbackBorderColor,
  borderColorOnHover,
  defaultBorderWidth: defaultBorderWidthWithDimension,
}: {
  border?: PerSideBorder;
  fallbackBorderColor: string;
  defaultBorderWidth: Dimension;
  borderColorOnHover: string;
}): { [key: `--${string}`]: string } => {
  const defaultBorderWidth = `${defaultBorderWidthWithDimension?.value}${defaultBorderWidthWithDimension?.mode}`;

  const borderColor = border?.top?.color ?? fallbackBorderColor;

  return {
    [BORDER_COLOR]: borderColor,
    [BORDER_COLOR_ON_HOVER]: borderColorOnHover,
    [DEFAULT_BORDER_COLOR]: fallbackBorderColor,
    [BOX_SHADOW_ON_HOVER]: generateBoxShadowCss(borderColorOnHover),
    [BORDER_TOP_WIDTH]: border?.top
      ? `${border?.top?.width?.value}${border?.top?.width?.mode}`
      : defaultBorderWidth ?? "0px",
    [BORDER_RIGHT_WIDTH]: border?.right
      ? `${border?.right?.width?.value}${border?.right?.width?.mode}`
      : defaultBorderWidth ?? "0px",
    [BORDER_BOTTOM_WIDTH]: border?.bottom
      ? `${border?.bottom?.width?.value}${border?.bottom?.width?.mode}`
      : defaultBorderWidth ?? "0px",
    [BORDER_LEFT_WIDTH]: border?.left
      ? `${border?.left?.width?.value}${border?.left?.width?.mode}`
      : defaultBorderWidth ?? "0px",
  };
};
