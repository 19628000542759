import React from "react";
import styled from "styled-components";
import { colors as sbThemeColors } from "styles/colors";

// Perf: styled component without props is more performant
// because it can be precomputed, that's why we use [data-visible]
const AnimatedSvg = styled.svg`
  padding: 4px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  &[data-visible="true"] {
    opacity: 1;
  }
`;

const verticalStyle = { transform: "translate(50%, 0)" };
const horizontalStyle = { transform: "translate(0, 50%)" };

const MeasuringMark = (props: {
  visible: boolean;
  lineDirection: "vertical" | "horizontal";
}) => {
  return props.lineDirection === "vertical" ? (
    <AnimatedSvg width="100%" height="100%" data-visible={props.visible}>
      <g style={verticalStyle}>
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="100%"
          stroke={sbThemeColors.ACCENT_BLUE_NEW_DARKER}
          strokeWidth="1"
        />
        <line
          x1="-4"
          y1="0"
          x2="4"
          y2="0"
          stroke={sbThemeColors.ACCENT_BLUE_NEW_DARKER}
          strokeWidth="2"
        />
        <line
          x1="-4 "
          y1="100%"
          x2="4"
          y2="100%"
          stroke={sbThemeColors.ACCENT_BLUE_NEW_DARKER}
          strokeWidth="2"
        />
      </g>
    </AnimatedSvg>
  ) : (
    <AnimatedSvg width="100%" height="100%" data-visible={props.visible}>
      <g style={horizontalStyle}>
        <line
          x1="0"
          y1="0"
          x2="100%"
          y2="0"
          stroke={sbThemeColors.ACCENT_BLUE_NEW_DARKER}
          strokeWidth="1"
        />
        <line
          x1="0"
          y1="-4"
          x2="0"
          y2="4"
          stroke={sbThemeColors.ACCENT_BLUE_NEW_DARKER}
          strokeWidth="2"
        />
        <line
          x1="100%"
          y1="-4"
          x2="100%"
          y2="4"
          stroke={sbThemeColors.ACCENT_BLUE_NEW_DARKER}
          strokeWidth="2"
        />
      </g>
    </AnimatedSvg>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default MeasuringMark;
