import { NonCustomTypography } from "@superblocksteam/shared";
import {
  PropsPanelCategory,
  type PropertyPaneConfig,
} from "legacy/constants/PropertyControlConstants";
import { TextAlign } from "legacy/constants/WidgetConstants";
import { VALIDATION_TYPES } from "legacy/constants/WidgetValidation";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { iconProperty, iconPositionProperty } from "../appearanceProperties";
import { ZERO_PADDING } from "../base/sizing";
import {
  paddingProperty,
  visibleProperties,
  sizeSection,
} from "../basePropertySections";
import {
  textStyleCombinedProperty,
  borderProperty,
  borderRadiusProperty,
  backgroundColorProperty,
} from "../styleProperties";
import { LinkWidgetProps } from "./types";
import {
  textVariantDefaultValue,
  textColorDefaultValue,
  paddingDefaultValue,
  textAlignmentDefaultValue,
  borderDefaultValue,
  borderRadiusDefaultValue,
} from "./utils";

const variantTextStyle = ({
  hidden,
  additionalUserSelectableVariants,
}: {
  hidden: PropertyPaneConfig["hidden"];
  additionalUserSelectableVariants?: NonCustomTypography[];
}) => {
  return textStyleCombinedProperty({
    label: "Text style",
    textStyleParentDottedPath: "textProps",
    defaultValueFn: {
      variant: ({ props }) => textVariantDefaultValue(props),
      "textColor.default": ({ props, theme }) =>
        textColorDefaultValue(props, theme),
    },
    hidden,
    additionalUserSelectableVariants,
  });
};

const config: PropertyPaneConfig[] = [
  {
    sectionName: "General",
    sectionCategory: PropsPanelCategory.Content,
    children: [
      {
        propertyName: "text",
        label: "Text",
        helpText: "Sets the text of the link",
        controlType: "INPUT_TEXT",
        placeholderText: "Enter text",
        isBindProperty: true,
        isTriggerProperty: false,
        visibility: "SHOW_NAME",
        defaultValue: "Click me!",
      },
      {
        propertyName: "url",
        label: "URL",
        helpText:
          "The URL to hyperlink to. Can be a relative path to another page (i.e. /users) or a full URL",
        controlType: "INPUT_TEXT",
        placeholderText: "https://superblocks.com",
        isBindProperty: true,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.URL_FOR_HYPERLINK,
      },
      {
        propertyName: "keepExistingQueryParams",
        label: "Keep existing query parameters",
        helpText: "Appends the current query parameters to the link URL",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      {
        propertyName: "openInNewTab",
        label: "Open in new tab",
        helpText: "Opens the link in a new tab",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.BOOLEAN,
      },
    ],
  },
  {
    sectionName: "Style",
    sectionCategory: PropsPanelCategory.Appearance,
    children: [
      {
        propertyName: "linkStyle",
        label: "Link style",
        controlType: "DROP_DOWN",
        helpText: "Changes the style of the link",
        options: [
          {
            label: "Link",
            value: "LINK",
          },
          {
            label: "Primary button",
            value: "PRIMARY_BUTTON",
          },
          {
            label: "Secondary button",
            value: "SECONDARY_BUTTON",
          },
          {
            label: "Tertiary button",
            value: "TERTIARY_BUTTON",
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
      },
      iconProperty(),
      iconPositionProperty(),
    ],
  },
  {
    sectionName: "Advanced",
    children: [
      paddingProperty({
        propertyCategory: PropsPanelCategory.Layout,
        themeValue: ({ props }) => {
          const value = paddingDefaultValue(props);
          return {
            value,
            treatAsNull: value === ZERO_PADDING,
          };
        },
      }),
      {
        propertyName: "textAlignment",
        propertyCategory: PropsPanelCategory.Appearance,
        helpText: "The horizontal alignment of the link text",
        label: "Text alignment",
        controlType: "RADIO_BUTTON_GROUP",
        defaultValueFn: ({ props }) => textAlignmentDefaultValue(props),
        hidden: (props: LinkWidgetProps) => {
          return props.width.mode === "fitContent";
        },
        options: [
          {
            icon: "LEFT_ALIGN",
            value: TextAlign.LEFT,
          },
          {
            icon: "CENTER_ALIGN",
            value: TextAlign.CENTER,
          },
          {
            icon: "RIGHT_ALIGN",
            value: TextAlign.RIGHT,
          },
        ],
        isBindProperty: false,
        isTriggerProperty: false,
      },
      // Link text style
      variantTextStyle({
        hidden: (props: LinkWidgetProps) => props.linkStyle !== "LINK",
      }),
      // Button text style
      variantTextStyle({
        additionalUserSelectableVariants: ["buttonLabel"],
        hidden: (props: LinkWidgetProps) => props.linkStyle === "LINK",
      }),
      backgroundColorProperty({
        propertyName: "backgroundColor",
        themeValue: ({ props }) =>
          props.linkStyle === "PRIMARY_BUTTON"
            ? { value: "colors.primary500", treatAsNull: false }
            : { value: "transparent", treatAsNull: true },
        hidden: (props: LinkWidgetProps) => props.linkStyle === "LINK",
      }),
      borderProperty({
        propertyName: "border",
        defaultValueFn: ({ props, theme }) => borderDefaultValue(props, theme),
        themeValue: ({ props, theme }) => {
          return {
            value: borderDefaultValue(props, theme),
            treatAsNull: props.linkStyle !== "SECONDARY_BUTTON",
          };
        },
        hidden: (props: LinkWidgetProps) => props.linkStyle === "LINK",
      }),
      borderRadiusProperty({
        propertyName: "borderRadius",
        themeValue: ({ props, theme }) => {
          return {
            value: borderRadiusDefaultValue(props, theme),
            treatAsNull: props.linkStyle !== "PRIMARY_BUTTON",
          };
        },
        hidden: (props: LinkWidgetProps) => props.linkStyle === "LINK",
      }),
      {
        helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
        propertyCategory: PropsPanelCategory.Appearance,
        propertyName: "animateLoading",
        label: "Loading animation",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
      },
      ...visibleProperties({ useJsExpr: false }),
      {
        propertyName: "isDisabled",
        propertyCategory: PropsPanelCategory.Interaction,
        label: "Disabled",
        helpText: "Disables user interaction with this component",
        controlType: "SWITCH",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: VALIDATION_TYPES.BOOLEAN,
      },
    ],
  },
  sizeSection({
    widthSupportsFitContent: true,
    heightSupportsFitContent: true,
    hidePadding: true,
  }),
];

export default config;
