function waitForTimeout(timeoutInMs: number): Promise<never> {
  return new Promise((_, reject) => {
    jest.useRealTimers();
    setTimeout(() => reject("Timeout exceeded"), timeoutInMs);
    jest.useFakeTimers();
  });
}

export async function timeoutPromise<T>(
  promise: Promise<T>,
  timeoutInMs: number,
): Promise<T> {
  const winner = await Promise.race([promise, waitForTimeout(timeoutInMs)]);

  return winner;
}

export type Deferred<T> = Promise<T> & {
  resolver: (args: T | PromiseLike<T>) => void;
  rejecter: (e: Error) => void;
};

export function makeDeferred<T>(): Deferred<T> {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let resolver: Deferred<T>["resolver"] = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let rejecter: Deferred<T>["rejecter"] = () => {};
  const promise = new Promise((resolve, reject) => {
    resolver = resolve;
    rejecter = reject;
  }) as Deferred<T>;
  promise.resolver = resolver;
  promise.rejecter = rejecter;
  return promise;
}
