import { Dimension } from "@superblocksteam/shared";
import {
  CanvasAlignment,
  CanvasDistribution,
  CanvasLayout,
  GridDefaults,
  WIDGETS_WITH_PADDED_FOCUS,
  WIDGET_PADDED_FOCUS_PX,
} from "legacy/constants/WidgetConstants";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import { useAppSelector } from "store/helpers";
import { isStackLayout } from "../StackLayout/utils";
import { getCanvasMinHeightFlattened } from "./sizing";
import { getCanvasMinWidthFlattened } from "./sizing/canvasWidthUtil";
import type { WidgetPropsRuntime } from "../BaseWidget";

const DEFAULT_FOCUS = "-1px";
const DEFAULT_SELECTION = "-2px";

const insetDefaults = Object.freeze({
  shouldInsetLeft: false,
  shouldInsetRight: false,
  shouldInsetTop: false,
  shouldInsetBottom: false,
  insetFocus: DEFAULT_FOCUS,
  insetSelection: DEFAULT_SELECTION,
});

const paddedDefaults = Object.freeze({
  ...insetDefaults,
  insetFocus: `-${WIDGET_PADDED_FOCUS_PX}px`,
});

export const useInsetInfo = (widget: WidgetPropsRuntime) => {
  const all = useAppSelector(getFlattenedCanvasWidgets);
  const parent = all[widget.parentId];

  const isInStack = isStackLayout(parent?.layout);
  const parentWidth =
    parent.internalWidth ?? parent?.width
      ? Dimension.toPx(
          parent.internalWidth ?? parent?.width,
          parent.parentColumnSpace!,
        ).value
      : parent?.gridColumns ?? Number.MAX_VALUE;
  const parentHeight = parent.height
    ? Dimension.toPx(parent.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value
    : Number.MAX_VALUE;

  const padding = parent?.padding;
  const paddingLeft = padding?.left?.value ?? Number.MAX_VALUE;
  const paddingTop = padding?.top?.value ?? Number.MAX_VALUE;
  const paddingRight = padding?.right?.value ?? Number.MAX_VALUE;
  const paddingBottom = padding?.bottom?.value ?? Number.MAX_VALUE;

  const margin = isInStack ? widget.margin : undefined;
  const marginLeft = margin?.left?.value ?? 0;
  const marginTop = margin?.top?.value ?? 0;
  const marginRight = margin?.right?.value ?? 0;
  const marginBottom = margin?.bottom?.value ?? 0;

  const needsPaddedFocus = WIDGETS_WITH_PADDED_FOCUS.includes(widget.type);

  const hasPaddingAll =
    paddingLeft > 0 && paddingTop > 0 && paddingRight > 0 && paddingBottom > 0;
  const hasMarginAll =
    marginLeft > 0 && marginTop > 0 && marginRight > 0 && marginBottom > 0;
  // if all padding GT 0
  // let's return defaults instead of calculating
  if (hasPaddingAll || hasMarginAll) {
    if (needsPaddedFocus) return paddedDefaults;
    return insetDefaults;
  }

  const indexInParent = parent?.children?.findIndex(
    (child: string) => child === widget.widgetId,
  );

  const childHeights = isInStack
    ? Dimension.toPx(
        getCanvasMinHeightFlattened(parent, all),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).value
    : parentHeight;
  const heightDiff = Math.max(parentHeight - childHeights, 0);
  const distribution = parent.distribution ?? CanvasDistribution.TOP;
  let topSpace = 0;
  let bottomSpace = 0;
  switch (distribution) {
    case CanvasDistribution.TOP:
      bottomSpace = heightDiff;
      break;
    case CanvasDistribution.CENTER:
      topSpace = heightDiff / 2;
      bottomSpace = heightDiff / 2;
      break;
    case CanvasDistribution.BOTTOM:
      topSpace = heightDiff;
      break;
  }

  const childWidths = isInStack
    ? Dimension.toPx(
        getCanvasMinWidthFlattened(parent, all),
        GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      ).value
    : parentWidth;
  const widthDiff = Math.max(parentWidth - childWidths, 0);
  const alignment = parent.alignment ?? CanvasAlignment.STRETCH;
  let leftSpace = 0;
  let rightSpace = 0;
  switch (alignment) {
    case CanvasAlignment.LEFT:
      rightSpace = widthDiff;
      break;
    case CanvasAlignment.CENTER:
      leftSpace = widthDiff / 2;
      rightSpace = widthDiff / 2;
      break;
    case CanvasAlignment.RIGHT:
      leftSpace = widthDiff;
      break;
  }

  const isFirstInParent = indexInParent === 0;
  const isLastInParent = indexInParent === (parent.children?.length ?? 0) - 1;

  let left, right, top, bottom;
  switch (parent.layout) {
    case CanvasLayout.HSTACK:
      left = isFirstInParent ? leftSpace : Number.MAX_VALUE;
      right = isLastInParent ? parentWidth - rightSpace : 0;
      top = topSpace;
      bottom = parentHeight - bottomSpace;
      break;
    case CanvasLayout.VSTACK:
      left = leftSpace;
      right = parentWidth - rightSpace;
      top = isFirstInParent ? topSpace : Number.MAX_VALUE;
      bottom = isLastInParent ? parentHeight - bottomSpace : 0;
      break;
    case CanvasLayout.FIXED:
    default:
      left = Dimension.toPx(widget.left, widget.parentColumnSpace).value;
      right =
        Dimension.toPx(widget.width, widget.parentColumnSpace).value + left;
      top = Dimension.toPx(widget.top, widget.parentRowSpace).value;
      bottom = Dimension.toPx(widget.height, widget.parentRowSpace).value + top;
      break;
  }

  const shouldInsetLeft = left === 0 && paddingLeft + marginLeft < 3;
  const shouldInsetTop = top === 0 && paddingTop + marginTop < 3;
  const shouldInsetRight =
    right === parentWidth && paddingRight + marginRight < 3;
  const shouldInsetBottom =
    bottom === parentHeight && paddingBottom + marginBottom < 3;

  const focusDefault = needsPaddedFocus
    ? `-${WIDGET_PADDED_FOCUS_PX}px`
    : DEFAULT_FOCUS;

  const insetFocus = `${shouldInsetTop ? "0" : focusDefault} 
    ${shouldInsetRight ? "0" : focusDefault} 
    ${shouldInsetBottom ? "0" : focusDefault} 
    ${shouldInsetLeft ? "0" : focusDefault}`;

  const insetSelection = `${shouldInsetTop ? "0" : DEFAULT_SELECTION} 
    ${shouldInsetRight ? "0" : DEFAULT_SELECTION} 
    ${shouldInsetBottom ? "0" : DEFAULT_SELECTION}
    ${shouldInsetLeft ? "0" : DEFAULT_SELECTION}`;

  return {
    shouldInsetLeft,
    shouldInsetRight,
    shouldInsetTop,
    shouldInsetBottom,
    insetFocus,
    insetSelection,
  };
};
