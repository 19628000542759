// Scope Blueprint components to avoid any confusion with our own types
import {
  MenuDivider as BlueprintMenuDivider,
  MenuItem as BlueprintMenuItem,
  MenuItemProps as BlueprintMenuItemProps,
} from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ChevronDown } from "assets/icons/common/chevron-down-dropdown.svg";
import DynamicSVG from "components/ui/DynamicSVG";
import { APP_MODE } from "legacy/reducers/types";
import { getTextCssClassFromVariant } from "legacy/themes/utils";
import { colors } from "styles/colors";
import { styleAsClass } from "styles/styleAsClass";
import { NavigationEvent } from "utils/polyfills/interceptNavigation";
import { TypographyProps } from "../typographyHooks";
import { type ComponentMenuItem, MenuItemType } from "./types";

type PropType = {
  item: ComponentMenuItem;
  index: number;
  appMode: APP_MODE | undefined;
  textProps?: TypographyProps;
  handleMissingRouteParamsOnEditMode: () => void;
};

const ChevronRightIcon = styled(ChevronDown)`
  transform: rotate(-90deg);
  path {
    stroke: ${colors.GREY_300} !important; // overrides Blueprint's default color for label icon
  }
`;

const IconClassName = styleAsClass`
  display: flex;
  align-self: center;
`;

const MENU_ITEMS_POPOVER_PROPS: BlueprintMenuItemProps["popoverProps"] = {
  hoverCloseDelay: 400, // avoids closing the menu when hovering over to submenus
  modifiers: {
    offset: {
      enabled: true,
      options: {
        offset: [0, 12], // leaves space between menu and submenus
      },
    },
  },
};

export const MenuItem = ({
  item,
  index,
  appMode,
  textProps,
  handleMissingRouteParamsOnEditMode,
}: PropType) => {
  if (item.type === MenuItemType.Divider) {
    return <BlueprintMenuDivider />;
  } else if (item.type === MenuItemType.Button) {
    throw new Error("Button menu items are not supported yet");
  }

  const children = item.children
    ? item.children.map((child, i) => (
        <MenuItem
          item={child}
          index={i}
          key={i}
          appMode={appMode}
          textProps={textProps}
          handleMissingRouteParamsOnEditMode={
            handleMissingRouteParamsOnEditMode
          }
        />
      ))
    : undefined;

  const typographyClassName =
    textProps?.textStyleVariant !== undefined
      ? getTextCssClassFromVariant(textProps.textStyleVariant)
      : "";

  const itemPosition = item.iconPosition ?? "LEFT";
  const text = item.icon ? (
    <div className="menu-item-text" style={{ color: textProps?.style?.color }}>
      {itemPosition === "LEFT" && <DynamicSVG size={18} iconName={item.icon} />}
      <span className={typographyClassName} style={textProps?.style}>
        {item.label}
      </span>
      {itemPosition === "RIGHT" && (
        <DynamicSVG size={18} iconName={item.icon} />
      )}
    </div>
  ) : (
    <span className={typographyClassName} style={textProps?.style}>
      {item.label}
    </span>
  );

  const menuItemProps: BlueprintMenuItemProps = {
    text,
  };

  const hasHref = "href" in item && item.href !== "";
  const hasMissingRouteParams =
    "hasMissingRouteParams" in item && item.hasMissingRouteParams;

  if (hasMissingRouteParams && appMode === APP_MODE.EDIT) {
    menuItemProps.onClick = handleMissingRouteParamsOnEditMode;
  } else if (hasHref && !hasMissingRouteParams) {
    menuItemProps.href = item.href;
    if (item.urlForInternalNavigation && !item.targetBlank) {
      menuItemProps.onClick = (e: React.MouseEvent<HTMLElement>) => {
        const forceNewTab = e.ctrlKey || e.metaKey;

        if (
          !forceNewTab &&
          item.urlForInternalNavigation !== undefined &&
          "navigation" in window
        ) {
          e.preventDefault();
          (window.navigation as any).dispatchEvent(
            new NavigationEvent(
              true,
              item.urlForInternalNavigation,
              undefined,
              false,
              false,
            ),
          );
        }
      };
    }

    if (item.targetBlank) {
      menuItemProps.target = "_blank";
    }
  }

  return (
    <BlueprintMenuItem
      {...menuItemProps}
      data-test={`item-${item.label}`}
      style={{ color: textProps?.style?.color }}
      labelElement={children ? <ChevronRightIcon /> : undefined}
      labelClassName={IconClassName}
      popoverProps={MENU_ITEMS_POPOVER_PROPS}
    >
      {children}
    </BlueprintMenuItem>
  );
};
