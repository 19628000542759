import { UrlDataState } from "legacy/reducers/entityReducers/appReducer";
import { APP_MODE } from "legacy/reducers/types";
import { buildLocalUrlFromRoute } from "utils/navigation/localNavigation";
import { getLocalizedUrl } from "../LinkWidget/localizeUrl";
import {
  ComponentMenuItem,
  LinkToType,
  ManualMenuItem,
  MenuChildrenType,
  MenuItemType,
} from "./types";
import type { RouteDef } from "@superblocksteam/shared";
import type { getSystemQueryParams } from "legacy/utils/queryParams";

type Context = {
  routes: { [id: string]: RouteDef };
  currentPageUrlState: UrlDataState;
  systemQueryParams: ReturnType<typeof getSystemQueryParams>;
  appMode: APP_MODE | undefined;
};

export function transformItem(
  item: ManualMenuItem,
  context: Context,
): ComponentMenuItem {
  if (item.type === MenuItemType.Divider) {
    return item;
  }

  if (item.childrenType === MenuChildrenType.Manual) {
    const children =
      item.manualChildren && item.manualChildren.length > 0
        ? item.manualChildren.map((child) => transformItem(child, context))
        : undefined;

    switch (item.type) {
      case MenuItemType.Button: {
        return {
          type: item.type,
          label: item.label,
          icon: item.icon,
          iconPosition: item.iconPosition,
          openOn: item.openOn,
          children,
        };
      }
      case MenuItemType.Link: {
        let href = "";
        let hasMissingRouteParams = undefined;
        let urlForInternalNavigation = undefined;

        if (item.linkTo === LinkToType.Page && item.routeId) {
          const route = context.routes[item.routeId];
          /**
           *  queryParams and routeParams already have their bindings evaluated.
           *
           * `item.queryParams` is a JSON stringified object:
           * - queryParams comes from the KEY_VALUE_INPUT prop control that stringifies their value
           *
           * `item.routeParams` is a JSON stringified object (if has been evaluated) or an object if there were no bindings.
           * It gets stringified in `dataTreeFactory`.
           *
           *
           * Other usage:
           * - When used as frontend event handler, route params are not stringified, and both query strings and route params are evaluated in `TriggerExecutionSaga.ts` (see `queryParamsStringEvaled`).
           * - When used as non-nested widget properties, route params might need to use VALIDATION_TYPES.OBJECT_OR_UNDEFINED for easier conversion.
           *
           */
          const queryParams = item.queryParams
            ? JSON.parse(item.queryParams)
            : {};

          const routeParams =
            typeof item.routeParams === "string"
              ? JSON.parse(item.routeParams)
              : item.routeParams;

          const result = buildLocalUrlFromRoute(route, {
            ...item,
            queryParams,
            routeParams,
            currentQueryParamsToKeep: context.currentPageUrlState.queryParams,
          });

          if (result.ok) {
            hasMissingRouteParams = false;

            const url = result.value;
            const localizedUrl = getLocalizedUrl({
              inputUrl: url.pathname + url.search,
              keepExistingQueryParams: false, // already applied
              currentPageUrlState: context.currentPageUrlState,
              systemQueryParams: context.systemQueryParams,
              appMode: context.appMode,
            });

            href = localizedUrl.url;
            urlForInternalNavigation = localizedUrl.urlForInternalNavigation;
          } else {
            hasMissingRouteParams = true;
          }
        } else if (item.linkTo === LinkToType.CustomUrl) {
          const { url, urlForInternalNavigation: _urlForInternalNavigation } =
            getLocalizedUrl({
              inputUrl: item.href,
              keepExistingQueryParams: false,
              currentPageUrlState: context.currentPageUrlState,
              systemQueryParams: context.systemQueryParams,
              appMode: context.appMode,
            });

          href = url;
          urlForInternalNavigation = _urlForInternalNavigation;
        }

        return {
          type: item.type,
          label: item.label,
          href: href,
          urlForInternalNavigation,
          icon: item.icon,
          iconPosition: item.iconPosition,
          targetBlank: item.targetBlank,
          children,
          hasMissingRouteParams,
        };
      }
      default: {
        throw new Error(`Invalid menu item type for ${item}`);
      }
    }
  } else {
    throw new Error(`Non-manual children types are implemented yet`);
  }
}
