import { Tooltip } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import useProximity from "hooks/ui/useProximity";
import { addSectionWidget } from "legacy/actions/widgetActions";
import { Layers } from "legacy/constants/Layers";
import { getEditorReadOnly } from "legacy/selectors/editorSelectors";

const Wrapper = styled.div`
  z-index: ${Layers.addSectionButton};
  position: absolute;
  width: 100%;

  &[data-placement="above"] {
    top: 0;
  }

  &[data-placement="below"] {
    bottom: 0;
  }

  transition: opacity 0.2s ease;
  opacity: 1;
  &[data-gone="true"] {
    opacity: 0;
  }
`;

// we need to use an odd number here so that each side has an even number of pixels
// because it needs to be exactly at the middle of the border if there are 2 columns
const BTN_SIZE = 17;
const AddBtn = styled.div`
  font-family: var(--font-family);
  position: absolute;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: ${BTN_SIZE}px;
  height: ${BTN_SIZE}px;
  font-size: 13px;
  font-weight: 500;
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.WHITE};
  background-color: ${(props) => props.theme.colors.ACCENT_BLUE_NEW_DARKER};
  border-radius: 100px;
  transition: background-color 0.2s ease;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(34, 39, 47, 0.06),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 0px 1px rgba(34, 39, 47, 0.16);

  pointer-events: auto;

  &:hover {
    background-color: ${(props) => props.theme.colors.ACCENT_BLUE_600};
  }

  &[data-placement="above"] {
    top: 0;
    transform: translate(-50%, -50%);
  }

  &[data-placement="above"][data-is-top-edge="true"] {
    transform: translate(-50%, -23%);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &[data-placement="below"] {
    transform: translate(-50%, 50%);
    bottom: 0;
  }

  &[data-read-only="true"] {
    cursor: not-allowed;
  }

  svg {
    pointer-events: none;
  }
`;

type AddSectionButtonProps = {
  sectionWidgetId: string;
  placement: "above" | "below";
  isTopEdge?: boolean;
  style?: React.CSSProperties;
  onlyShowWhenClose?: boolean;
  padding: number;
};

const AddSectionButton = ({
  sectionWidgetId,
  placement,
  isTopEdge,
  style = {},
  onlyShowWhenClose = false,
  padding,
}: AddSectionButtonProps) => {
  const dispatch = useDispatch();
  const addSection = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      dispatch(addSectionWidget(sectionWidgetId, placement));
    },
    [dispatch, sectionWidgetId, placement],
  );
  const [ref, isClose] = useProximity(140, !onlyShowWhenClose);
  const show = isClose || !onlyShowWhenClose;
  const isReadOnly = useSelector(getEditorReadOnly);

  return (
    <Wrapper
      data-placement={placement}
      data-is-top-edge={isTopEdge}
      data-gone={!show}
      style={{
        left: `${padding / 2}px`,
        width: `calc(100% - ${padding}px)`,
        fontFamily: "var(--font-family)",
      }}
    >
      <Tooltip
        title={
          <span style={{ fontFamily: "var(--font-family)" }}>Add Section</span>
        }
        mouseEnterDelay={0.2}
        mouseLeaveDelay={0}
      >
        <AddBtn
          ref={ref}
          onClick={isReadOnly ? undefined : addSection}
          style={style}
          data-maintain-widget-focus="true"
          data-test={`add-section-btn-${placement}`}
          data-placement={placement}
          data-is-top-edge={isTopEdge}
          data-gone={!show}
          data-read-only={isReadOnly}
        >
          <PlusIcon width={14} height={14} />
        </AddBtn>
      </Tooltip>
    </Wrapper>
  );
};

export default AddSectionButton;
