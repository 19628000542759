import { WidgetTypes } from "@superblocksteam/shared";
import { Button } from "antd";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/common/plus.svg";
import { useFeatureFlag } from "hooks/ui";
import {
  getFocusedWidget,
  getWidget,
  selectPageWidgetIsEmpty,
} from "legacy/selectors/sagaSelectors";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { Flag } from "store/slices/featureFlags/models/Flags";
import {
  setInsertionContext,
  showUiBlocksModal,
} from "store/slices/uiBlocks/slice";
import { colors } from "styles/colors";

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 6px;
  pointer-events: auto;

  &:hover {
    background: ${colors.ACCENT_BLUE_500_25};
  }
`;

export function UIBlockOverlay({ widgetId }: { widgetId: string }) {
  const dispatch = useAppDispatch();

  const isBlocksEnabled = useFeatureFlag(Flag.UI_BLOCKS_FOR_USERS);

  const widget = useAppSelector(
    useCallback((state) => getWidget(state, widgetId), [widgetId]),
  );
  const parentWidget = useAppSelector(
    useCallback(
      (state) => getWidget(state, widget?.parentId),
      [widget?.parentId],
    ),
  );
  const grandparentWidget = useAppSelector(
    useCallback(
      (state) => getWidget(state, parentWidget?.parentId),
      [parentWidget?.parentId],
    ),
  );

  const pageWidgetIsEmpty = useAppSelector((state) =>
    selectPageWidgetIsEmpty(state),
  );

  const focusedWidget = useAppSelector(useCallback(getFocusedWidget, []));

  const isFocused = useMemo(() => {
    // Focus happens on the container/tab widget, so we should check if it has focus
    if (
      (parentWidget?.type === WidgetTypes.CONTAINER_WIDGET ||
        parentWidget?.type === WidgetTypes.TABS_WIDGET ||
        parentWidget.type === WidgetTypes.SECTION_WIDGET) &&
      focusedWidget?.widgetId === parentWidget.widgetId
    ) {
      return true;
    }

    return focusedWidget?.widgetId === widgetId;
  }, [focusedWidget, parentWidget, widgetId]);

  const { sectionInsertionIndex, columnInsertionIndex } = useMemo(() => {
    const canvasIndex = parentWidget?.children?.indexOf(widgetId);
    const sectionIndex = grandparentWidget?.children?.indexOf(
      parentWidget?.widgetId ?? "",
    );

    return {
      sectionInsertionIndex:
        sectionIndex != null ? sectionIndex + 1 : undefined,
      columnInsertionIndex: canvasIndex != null ? canvasIndex + 1 : undefined,
    };
  }, [parentWidget, grandparentWidget, widgetId]);

  if (
    !isBlocksEnabled ||
    (widget?.children && widget.children.length > 0) ||
    !isFocused ||
    pageWidgetIsEmpty
  ) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: 100,
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
      }}
    >
      <StyledButton
        type="link"
        icon={<PlusIcon width="16px" height="16px" />}
        onClick={() => {
          dispatch(
            setInsertionContext({
              insertionTargetId: widgetId,
              cursorInsertionIndexes: {
                sectionInsertionPosition: sectionInsertionIndex,
                columnInsertionPosition: columnInsertionIndex,
              },
            }),
          );
          dispatch(showUiBlocksModal());
        }}
      >
        Add UI block
      </StyledButton>
    </div>
  );
}
