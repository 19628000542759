import { Classes, Popover, PopoverProps } from "@blueprintjs/core";
import { Dimension } from "@superblocksteam/shared/src/types";
import React from "react";
import DynamicSVG from "components/ui/DynamicSVG";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { TextStyleWithVariant } from "legacy/themes";
import { BUTTON_PADDING } from "legacy/themes/constants";
import { useAppSelector } from "store/helpers";
import { styleAsClass } from "styles/styleAsClass";
import ButtonComponent from "../ButtonWidget/ButtonComponent";
import { ButtonComponentProps } from "../ButtonWidget/types";
import { useTypographyStyling } from "../typographyHooks";
import { MenuList, MenuListProps } from "./MenuList";

// Applies to the popover element
const PopoverElementWrapper = styleAsClass`
 &&&& .${Classes.POPOVER_CONTENT} {
    // By default this class has overflow: hidden;
    // which prevents 2nd-level sub-menus from showing
    overflow: visible;

    padding: 0;
    border-radius: 4px;

    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16) !important; // Overrides Blueprint's default shadow for sub-menus
  }
`;

// Applies to popover target (the menu button)
const PopoverTargetWrapper = styleAsClass`
  &&&&.${Classes.POPOVER_TARGET} > * {
    height: 100%; // allow button to be expanded
  }
`;

export interface MenuComponentProps {
  widgetId?: string;
  items: MenuListProps["items"];
  isOpen?: boolean;

  onClose?: (event?: React.SyntheticEvent<HTMLElement, Event>) => void;
  onBeforeMenuOpen?: () => void;

  // Button props
  buttonProps: Omit<
    ButtonComponentProps,
    "onClick" | "isLoading" | "icon" | "width" | "maxWidth" | "textProps"
  > & {
    icon?: string;
    width: Dimension;
    maxWidth?: Dimension;
    textStyle?: TextStyleWithVariant;
  };

  menuProps: Pick<PopoverProps, "placement"> & {
    textStyle?: TextStyleWithVariant;
  };
}

export default function MenuComponentWrapper(props: MenuComponentProps) {
  const appMode = useAppSelector(getAppMode);
  const buttonTextProps = useTypographyStyling({
    textStyle: props.buttonProps.textStyle,
    defaultTextStyleVariant: "buttonLabel",
  });

  const menuTextProps = useTypographyStyling({
    textStyle: props.menuProps.textStyle,
    defaultTextStyleVariant: "body2",
  });

  const menu =
    props.items.length > 0 ? (
      <MenuList items={props.items} textProps={menuTextProps} />
    ) : (
      <div />
    );

  return (
    <Popover
      content={menu}
      placement={props.menuProps.placement ?? "bottom-start"}
      fill={true}
      popoverClassName={PopoverElementWrapper}
      className={PopoverTargetWrapper}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ButtonComponent
        onClick={props.onBeforeMenuOpen}
        text={props.buttonProps.text}
        disabled={props.buttonProps.disabled}
        isLoading={false}
        buttonStyle={props.buttonProps.buttonStyle}
        width={props.buttonProps.width.mode === "fitContent" ? "auto" : "100%"}
        textColor={buttonTextProps?.style?.color}
        backgroundColor={props.buttonProps.backgroundColor}
        maxWidth={props.buttonProps.maxWidth?.value}
        textAlignment={props.buttonProps.textAlignment}
        border={props.buttonProps.border}
        borderRadius={props.buttonProps.borderRadius}
        padding={props.buttonProps.padding ?? BUTTON_PADDING}
        icon={
          props.buttonProps.icon ? (
            <DynamicSVG size={18} iconName={props.buttonProps.icon} />
          ) : (
            <></>
          )
        }
        iconPosition={props.buttonProps.iconPosition ?? "LEFT"}
        textProps={buttonTextProps}
        data-test="menu-button"
        appMode={appMode}
        widgetId={props.widgetId}
        ddogActionName="menu-widget-button"
        version="v2"
      />
    </Popover>
  );
}
