import { Popover } from "@blueprintjs/core";
import { Dimension, WidgetPosition } from "@superblocksteam/shared";
import React, {
  ReactNode,
  useState,
  useEffect,
  forwardRef,
  Ref,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDrag as useDragGesture } from "react-use-gesture";
import styled, { StyledComponent, DefaultTheme } from "styled-components";
import { NotifierTooltipBody } from "components/ui/NotifierTooltip";
import { usePointerDownOutside, usePrevious } from "hooks/ui";
import { Layers } from "legacy/constants/Layers";
import {
  CanvasAlignment,
  CanvasLayout,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import {
  getEditorReadOnly,
  getFlattenedCanvasWidget,
} from "legacy/selectors/editorSelectors";
import { useAppSelector } from "store/helpers";
import { AppState } from "store/types";
import { scaledXYCoord } from "../../../utils/size";
import { getResponsiveCanvasScaleFactor } from "../../selectors/applicationSelectors";
import { HandleStylesProps } from "./ResizeStyledComponents";
import SelectionWrapper from "./SelectionAndFocusWrapper";
import { SizeOverlay } from "./SizeOverlay";
import { isDynamicSize } from "./sizing";
import { useInsetInfo } from "./useInsetInfo";
import type { WidgetPropsRuntime } from "../BaseWidget";

const ResizeWrapper = styled.div`
  display: block;
  position: relative;
  z-index: ${Layers.resizeWrapper};
  transition: transform 0.2 ease-in, width 0.2s ease-in, height 0.2s ease-in;
  will-change: transform, height, width;

  &[data-nointeract="false"] {
    * {
      pointer-events: none;
    }
  }
`;

const getSnappedValues = (
  xyCoordDiff: { x: number; y: number },
  snapGrid: { x: number; y: number },
) => {
  return {
    x: Math.round(xyCoordDiff.x / snapGrid.x) * snapGrid.x,
    y: Math.round(xyCoordDiff.y / snapGrid.y) * snapGrid.y,
  };
};

const getHandles = (
  props: ResizableProps,
  setNewDimensions: (rect: {
    width?: number;
    height?: number;
    x?: number;
    y?: number;
  }) => void,
  newDimensions: {
    width?: number;
    height?: number;
    x?: number;
    y?: number;
    reset: boolean;
  },
) => {
  const handles: Array<
    Pick<
      ResizableHandleProps,
      "dragCallback" | "component" | "disabledText" | "tooltipDirection"
    > & {
      cursor: string;
    }
  > = [];

  if (props.handles.left) {
    handles.push({
      dragCallback: (x: number) => {
        setNewDimensions({
          width: props.componentWidth - x,
          x: x,
        });
      },
      component: props.handles.left.component,
      disabledText: props.handles.left.disabledText,
      tooltipDirection: "left",
      cursor: "col-resize",
    });
  }
  if (props.handles.right) {
    handles.push({
      dragCallback: (x: number) => {
        setNewDimensions({
          width: props.componentWidth + x,
          x: newDimensions.x,
        });
      },
      component: props.handles.right.component,
      disabledText: props.handles.right.disabledText,
      tooltipDirection: "right",
      cursor: "col-resize",
    });
  }
  if (props.handles.bottom) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          height: props.componentHeight + y,
          y: newDimensions.y,
        });
      },
      component: props.handles.bottom.component,
      disabledText: props.handles.bottom.disabledText,
      tooltipDirection: "bottom",
      cursor: "row-resize",
    });
  }
  if (props.handles.top) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          height: props.componentHeight - y,
          y: y,
        });
      },
      component: props.handles.top.component,
      disabledText: props.handles.top.disabledText,
      tooltipDirection: "top",
      cursor: "row-resize",
    });
  }
  if (props.handles.bottomRight) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth + x,
          height: props.componentHeight + y,
          x: newDimensions.x,
          y: newDimensions.y,
        });
      },
      component: props.handles.bottomRight.component,
      disabledText: props.handles.bottomRight.disabledText,
      cursor: "se-resize",
    });
  }
  if (props.handles.bottomLeft) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth - x,
          height: props.componentHeight + y,
          x,
          y: newDimensions.y,
        });
      },
      component: props.handles.bottomLeft.component,
      disabledText: props.handles.bottomLeft.disabledText,
      cursor: "sw-resize",
    });
  }
  if (props.handles.topRight) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth + x,
          height: props.componentHeight - y,
          x: newDimensions.x,
          y: y,
        });
      },
      component: props.handles.topRight.component,
      disabledText: props.handles.topRight.disabledText,
      cursor: "ne-resize",
    });
  }
  if (props.handles.topLeft) {
    handles.push({
      dragCallback: (x: number, y: number) => {
        setNewDimensions({
          width: props.componentWidth - x,
          height: props.componentHeight - y,
          x: x,
          y: y,
        });
      },
      component: props.handles.topLeft.component,
      disabledText: props.handles.topLeft.disabledText,
      cursor: "nw-resize",
    });
  }
  return handles;
};

const useDisplayDimensions = (
  widget: WidgetPropsRuntime,
  widthMode: WidgetPosition["width"]["mode"],
  heightMode: WidgetPosition["height"]["mode"],
  newDimensionsPx: {
    width: number;
    height: number;
  },
) => {
  const parent = useAppSelector((state: AppState) =>
    getFlattenedCanvasWidget(state, widget.parentId),
  );
  const parentIsStretchedVStack =
    parent?.layout === CanvasLayout.VSTACK &&
    parent?.alignment === CanvasAlignment.STRETCH;

  return useMemo(() => {
    let displayWidth;
    if (widthMode === "px") {
      displayWidth = Dimension.build(
        newDimensionsPx.width + WIDGET_PADDING * 2,
        widthMode,
      );
    } else {
      displayWidth = Dimension.build(
        (newDimensionsPx.width + WIDGET_PADDING * 2) / widget.parentColumnSpace,
        widthMode,
      );
    }
    if (parentIsStretchedVStack) {
      displayWidth = Dimension.fillParent(1);
    }

    let displayHeight;
    if (heightMode === "px") {
      displayHeight = Dimension.build(
        newDimensionsPx.height + WIDGET_PADDING * 2,
        heightMode,
      );
    } else {
      displayHeight = Dimension.build(
        (newDimensionsPx.height + WIDGET_PADDING * 2) / widget.parentRowSpace,
        heightMode,
      );
    }
    return { displayWidth, displayHeight };
  }, [
    heightMode,
    newDimensionsPx.height,
    newDimensionsPx.width,
    parentIsStretchedVStack,
    widget.parentColumnSpace,
    widget.parentRowSpace,
    widthMode,
  ]);
};

type ResizableHandleProps = {
  dragCallback: (x: number, y: number) => void;
  component: StyledComponent<"div", DefaultTheme, HandleStylesProps>;
  onStart: () => void;
  onStop: () => void;
  snapGrid: {
    x: number;
    y: number;
  };
  initialSnapOffset?: {
    width: number;
    height: number;
  };
  hasInvalidProps: boolean;
  heightMode: WidgetPosition["height"]["mode"];
  readOnly?: boolean;
  disabledText?: string;
  tooltipDirection?: "top" | "bottom" | "left" | "right";

  insetLeft: boolean;
  insetRight: boolean;
  insetTop: boolean;
  insetBottom: boolean;
};

const ResizableHandle = (props: ResizableHandleProps) => {
  const canvasScaleFactor = useAppSelector(getResponsiveCanvasScaleFactor);

  const bind = useDragGesture(
    ({ first, last, dragging, movement: [mx, my], memo }) => {
      const scaledXYCoordDiff = scaledXYCoord(
        {
          x: mx,
          y: my,
        },
        canvasScaleFactor,
      );
      const snapped = getSnappedValues(scaledXYCoordDiff, props.snapGrid);
      const correctedSnap = {
        x: snapped.x - (props?.initialSnapOffset?.width || 0),
        y: snapped.y - (props?.initialSnapOffset?.height || 0),
      };
      if (dragging && memo && (snapped.x !== memo.x || snapped.y !== memo.y)) {
        props.dragCallback(correctedSnap.x, correctedSnap.y);
      }
      if (first) {
        props.onStart();
      }
      if (last) {
        props.onStop();
      }
      return snapped;
    },
  );

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const disabledDragBind = useDragGesture(({ first, last }) => {
    if (first) {
      props.onStart();
      setIsTooltipVisible(true);
    }
    if (last) {
      props.onStop();
      timerRef.current = setTimeout(() => {
        setIsTooltipVisible(false);
      }, 6000);
    }
  });

  const timerRef = React.useRef<any>();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(() => {
    setIsTooltipVisible(false);
    clearTimeout(timerRef.current);
  }, [setIsTooltipVisible]);
  usePointerDownOutside({
    wrapperRefs: [tooltipRef],
    onClickOutside: handleClickOutside,
  });
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handlers = useMemo(() => {
    if (props.disabledText) {
      return disabledDragBind();
    }
    if (props.readOnly) {
      return {};
    }
    return bind();
  }, [props.readOnly, props.disabledText, bind, disabledDragBind]);

  return (
    <props.component
      {...handlers}
      hasInvalidProps={props.hasInvalidProps}
      isValid={true}
      heightMode={props.heightMode}
      className="t--resize-handle"
      data-maintain-widget-focus="true"
      style={props.readOnly ? { cursor: "not-allowed" } : undefined}
      invisible={!!props.disabledText}
      insetLeft={props.insetLeft}
      insetRight={props.insetRight}
      insetTop={props.insetTop}
      insetBottom={props.insetBottom}
    >
      {props.disabledText && isTooltipVisible && (
        <PopoverTargetContainer>
          <Popover
            minimal
            isOpen={isTooltipVisible}
            popoverClassName={"bp5-unstyled-popover"}
            placement={props.tooltipDirection ?? "auto"}
            content={
              <NotifierTooltipBody>{props.disabledText}</NotifierTooltipBody>
            }
          >
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
              }}
            />
          </Popover>
        </PopoverTargetContainer>
      )}
    </props.component>
  );
};

const PopoverTargetContainer = styled.div`
  z-index: ${Layers.resizer + 1};
  position: absolute;
  height: 100%;
  width: 100%;
  > * {
    width: 100%;
    height: 100%;
  }
`;

type HandleConfig = {
  component: StyledComponent<"div", DefaultTheme>;
  disabledText?: string;
};

export interface DragTransformer {
  transform(size: {
    width?: number;
    height?: number;
    x?: number;
    y?: number;
  }): {
    width?: number;
    height?: number;
    x?: number;
    y?: number;
  };

  reset(): void;
}

export type ResizableProps = {
  handles: {
    left?: HandleConfig;
    top?: HandleConfig;
    bottom?: HandleConfig;
    right?: HandleConfig;
    bottomRight?: HandleConfig;
    topLeft?: HandleConfig;
    topRight?: HandleConfig;
    bottomLeft?: HandleConfig;
  };
  componentWidth: number;
  componentHeight: number;
  children: ReactNode;
  onStart: () => void;
  onStop: (
    size: { width: number; height: number },
    position: { x: number; y: number },
  ) => void;
  onResize: (size: { width: number; height: number }) => void;
  snapGrid: { x: number; y: number };
  enable: boolean;
  isSelected: boolean;
  isSpaceAvailable: (
    size: { width: number; height: number },
    position: { x: number; y: number },
  ) => boolean;
  dragSizeTransformer?: DragTransformer;
  className?: string;
  hasInvalidProps: boolean;
  widget: WidgetPropsRuntime;
  insetBottomHandleOverride?: boolean;
};

const Resizable = forwardRef(
  (props: ResizableProps, ref: Ref<HTMLDivElement>) => {
    const heightMode = props.widget.height.mode;
    const widthMode = props.widget.width.mode;

    const [pointerEvents, togglePointerEvents] = useState(true);
    const [newDimensions, set] = useState({
      width: props.componentWidth,
      height: props.componentHeight,
      x: 0,
      y: 0,
      reset: false,
    });

    // We need to lock the x and/or y dimensions when the user is resizing
    // This is because auto height can cause the height to change
    const [lockX, setLockX] = useState(false);
    const [lockY, setLockY] = useState(false);

    const editorReadOnly = useAppSelector(getEditorReadOnly);

    const { isSpaceAvailable, dragSizeTransformer } = props;
    const setNewDimensions = useCallback(
      (_rect: { width?: number; height?: number; x?: number; y?: number }) => {
        const rect = {
          ..._rect,
          ...dragSizeTransformer?.transform(_rect),
        };
        const {
          width = props.componentWidth,
          height = props.componentHeight,
          x = 0,
          y = 0,
        } = rect;

        if (rect.width || rect.x) {
          setLockX(true);
        }
        if (rect.height || rect.y) {
          if (isDynamicSize(heightMode)) {
            // This is disabled at the handle level, but return early here anyway
            return;
          } else {
            setLockY(true);
          }
        }

        if (
          (props.componentHeight <= 0 || props.componentWidth <= 0) &&
          (width <= 0 || height <= 0)
        ) {
          // this is to allow widgets that have somehow gotten into a broken state to be recovered
          set({
            width: Math.max(width, 1),
            height: Math.max(height, 1),
            x,
            y,
            reset: false,
          });
        }

        if (isSpaceAvailable({ width, height }, { x, y })) {
          set({ width, height, x, y, reset: false });
        }
      },
      [
        heightMode,
        isSpaceAvailable,
        props.componentHeight,
        props.componentWidth,
        dragSizeTransformer,
      ],
    );

    // we use a ref for the locks because we don't want to resize when the locks change
    const lockXRef = React.useRef(lockX);
    const lockYRef = React.useRef(lockY);
    lockXRef.current = lockX;
    lockYRef.current = lockY;
    useEffect(() => {
      const lockX = lockXRef.current;
      const lockY = lockYRef.current;
      set((current) => ({
        x: lockX ? current.x : 0,
        width: lockX ? current.width : props.componentWidth,
        y: lockY ? current.y : 0,
        height: lockY ? current.height : props.componentHeight,
        reset: true,
      }));
    }, [props.componentHeight, props.componentWidth]);

    const handles = useMemo(
      () => getHandles(props, setNewDimensions, newDimensions),
      [newDimensions, props, setNewDimensions],
    );

    const isResizingInternal = useRef(false);
    const [wasResizing, setWasResizing] = useState(false);
    const setIsResizingInternal = useCallback((value: boolean) => {
      isResizingInternal.current = value;
      if (value) {
        setWasResizing(true);
      } else {
        setTimeout(() => {
          setWasResizing(false);
        }, 200);
      }
    }, []);

    const { onStop } = props;
    const onResizeStop: () => void = useCallback(() => {
      document.body.style.cursor = ""; // reset cursor
      togglePointerEvents(true);
      onStop(
        {
          width: newDimensions.width + WIDGET_PADDING * 2,
          height: newDimensions.height + WIDGET_PADDING * 2,
        },
        {
          x: newDimensions.x,
          y: newDimensions.y,
        },
      );
      setIsResizingInternal(false);
      setLockX(false);
      setLockY(false);
      dragSizeTransformer?.reset();
    }, [
      newDimensions.height,
      newDimensions.width,
      newDimensions.x,
      newDimensions.y,
      onStop,
      dragSizeTransformer,
      setIsResizingInternal,
    ]);

    // if the drag handles disappear and we're in the middle of a resize, stop the resize
    useEffect(() => {
      if (!props.enable && isResizingInternal.current) {
        onResizeStop();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.enable]);

    useEffect(() => {
      if (!isResizingInternal.current) return;
      props.onResize({
        width: newDimensions.width + WIDGET_PADDING * 2,
        height: newDimensions.height + WIDGET_PADDING * 2,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newDimensions.height, newDimensions.width, props.onResize]);

    const { onStart, snapGrid, hasInvalidProps } = props;

    // There is normally a snap grid (12,12) or (1,1) but we could be off the grid if our item is set to pixels,
    // so we need to understand how far off the grid we are at the start
    const initialSnapOffset = useMemo(() => {
      const upHeight =
        (props.componentHeight + WIDGET_PADDING * 2) % snapGrid.y;
      const downHeight = -(snapGrid.y - upHeight);
      const height =
        Math.abs(upHeight) < Math.abs(downHeight) ? upHeight : downHeight;

      const upWidth = (props.componentWidth + WIDGET_PADDING * 2) % snapGrid.x;
      const downWidth = -(snapGrid.x - upWidth);
      const width =
        Math.abs(upWidth) < Math.abs(downWidth) ? upWidth : downWidth;

      return {
        width,
        height,
      };
    }, [props.componentHeight, props.componentWidth, snapGrid.x, snapGrid.y]);

    const {
      shouldInsetLeft,
      shouldInsetRight,
      shouldInsetTop,
      shouldInsetBottom,
      insetFocus,
      insetSelection,
    } = useInsetInfo(props.widget);

    const renderHandles = useMemo(
      () =>
        handles.map((handle, index) => (
          <ResizableHandle
            {...handle}
            key={index}
            onStart={() => {
              togglePointerEvents(false);
              onStart();
              setIsResizingInternal(true);
              document.body.style.cursor = handle.cursor;
            }}
            heightMode={props.widget.height.mode}
            onStop={onResizeStop}
            snapGrid={snapGrid}
            initialSnapOffset={initialSnapOffset}
            hasInvalidProps={hasInvalidProps}
            readOnly={editorReadOnly}
            insetLeft={shouldInsetLeft}
            insetRight={shouldInsetRight}
            insetTop={shouldInsetTop}
            insetBottom={
              shouldInsetBottom || props.insetBottomHandleOverride === true
            }
          />
        )),
      [
        editorReadOnly,
        handles,
        hasInvalidProps,
        initialSnapOffset,
        onResizeStop,
        onStart,
        props.insetBottomHandleOverride,
        props.widget.height.mode,
        shouldInsetBottom,
        shouldInsetLeft,
        shouldInsetRight,
        shouldInsetTop,
        snapGrid,
        setIsResizingInternal,
      ],
    );

    const oldHeight = usePrevious(props.componentHeight);
    const oldWidth = usePrevious(props.componentWidth);
    const springTo = useMemo(() => {
      const isStackLayout =
        props.widget.parentLayout === CanvasLayout.VSTACK ||
        props.widget.parentLayout === CanvasLayout.HSTACK;

      let width: string | number;
      if (
        widthMode === "fillParent" &&
        [CanvasLayout.VSTACK, CanvasLayout.HSTACK].includes(
          props.widget.parentLayout || CanvasLayout.FIXED,
        )
      ) {
        width = "100%";
      } else if (!wasResizing) {
        // why does height does not follow the same logic?
        // fit content widths propagate differently from height
        width = props.componentWidth;
      } else {
        width = newDimensions.width;
      }

      const height =
        heightMode === "fillParent" && isStackLayout
          ? "100%"
          : newDimensions.height;

      const heightChangedAndNotLocked =
        oldHeight !== props.componentHeight && !lockY;
      const widthChangedAndNotLocked =
        oldWidth !== props.componentWidth && !lockX;
      let transform = `translate3d(${newDimensions.x}px,${newDimensions.y}px,0)`;
      if (heightChangedAndNotLocked || widthChangedAndNotLocked) {
        // here we want to skip the transform if the incoming height or width has changed and is not locked
        // we can't wait for the setState to update the dimensions because the x,y updates are updated
        // at a higher level one frame earlier
        const tX = lockX ? newDimensions.x : 0;
        const tY = lockY ? newDimensions.y : 0;
        transform = `translate3d(${tX}px,${tY}px,0)`;
      }

      return {
        width,
        height,
        transform,
      };
    }, [
      props.widget.parentLayout,
      props.componentHeight,
      props.componentWidth,
      widthMode,
      newDimensions.width,
      newDimensions.height,
      newDimensions.x,
      newDimensions.y,
      heightMode,
      oldHeight,
      lockY,
      oldWidth,
      lockX,
      wasResizing,
    ]);

    const { displayWidth, displayHeight } = useDisplayDimensions(
      props.widget,
      widthMode,
      heightMode,
      newDimensions,
    );

    return (
      <ResizeWrapper
        ref={ref}
        style={springTo}
        className={props.className}
        data-nointeract={pointerEvents}
      >
        {props.children}
        <SelectionWrapper
          widgetType={props.widget.type}
          widgetId={props.widget.widgetId}
          widgetName={props.widget.widgetName}
          hasInvalidProps={props.hasInvalidProps}
          focusedRectInset={insetFocus}
          selectedRectInset={insetSelection}
        />
        {props.enable && renderHandles}
        {props.enable && props.widget.parentLayout !== CanvasLayout.FIXED && (
          <SizeOverlay
            width={displayWidth}
            height={displayHeight}
            widgetId={props.widget.widgetId}
            targetNode={(ref as any).current}
          />
        )}
      </ResizeWrapper>
    );
  },
);

Resizable.displayName = "Resizable";

export default Resizable;
