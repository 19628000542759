import {
  PropsPanelCategory,
  type PropertyPaneCategoryConfig,
} from "legacy/constants/PropertyControlConstants";
import { CodeWidgetLanguages } from "legacy/constants/WidgetConstants";
import { PanelCategory } from "legacy/pages/Editor/PropertyPane/propertyPaneCategoryUtils";
import { ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT } from "pages/Editors/AppBuilder/Sidebar/PropertyControlCommons";
import { sizeSection, visibleProperties } from "../basePropertySections";
import { getPopoverConfig } from "../eventHandlerPanel";
import { typographyProperties } from "../styleProperties";
import { DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT } from "./constants";
import { CodeWidgetProps } from "./types";

const config: PropertyPaneCategoryConfig<CodeWidgetProps>[] = [
  PanelCategory(PropsPanelCategory.Content, [
    {
      helpText: "Sets a label text for the code editor",
      propertyName: "label",
      label: "Label",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter label text",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "Label",
    },
    {
      propertyName: "mode",
      label: "Language",
      controlType: "DROP_DOWN",
      helpText: "Language ",
      options: [
        {
          label: "HJSON (human readable)",
          value: CodeWidgetLanguages.HJSON,
        },
        {
          label: "JSON (strict)",
          value: CodeWidgetLanguages.JSON,
        },
        {
          label: "Ruby HTML Template (ERB)",
          value: CodeWidgetLanguages.RUBY_ERB,
        },
        {
          label: "HTML",
          value: CodeWidgetLanguages.HTML,
        },
        {
          label: "Markdown",
          value: CodeWidgetLanguages.MARKDOWN,
        },
        {
          label: "Text",
          value: CodeWidgetLanguages.TEXT,
        },
        {
          label: "Python",
          value: CodeWidgetLanguages.PYTHON,
        },
        {
          label: "JavaScript",
          value: CodeWidgetLanguages.JAVASCRIPT,
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: "initialValue",
      label: "Default value",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter code",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
  ]),
  PanelCategory(PropsPanelCategory.Appearance, [
    ...typographyProperties({
      propertyNameForHumans: "Label",
      hiddenIfPropertyNameIsNullOrFalse: "label",
      textStyleParentDottedPath: "labelProps",
      defaultVariant: DEFAULT_CODE_WIDGET_LABEL_TEXT_STYLE_VARIANT,
    }),
    {
      propertyName: "lineWrapping",
      label: "Line wrapping",
      helpText: "Whether Code Editor should scroll or wrap for long lines",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "showLineNumbers",
      label: "Line numbers",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
      defaultValue: true, // Show this switch as ON for code editors created before this property was introduced
    },
    {
      propertyName: "animateLoading",
      label: "Loading animation",
      helpText: ANIMATE_LOADING_PROPERTY_CONTROL_HELP_TEXT,
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
  ]),
  PanelCategory(PropsPanelCategory.Layout, [
    sizeSection({
      heightSupportsFitContent: true,
    }),
    ...visibleProperties({ useJsExpr: false }),
  ]),
  PanelCategory(PropsPanelCategory.Interaction, [
    {
      propertyName: "newlineCharacter",
      label: "Newline char",
      controlType: "DROP_DOWN",
      helpText:
        "Determines the newline character used in the code editor and its output",
      options: [
        {
          label: "AUTO",
          value: undefined,
        },
        {
          label: "LF (Unix, Linux, Mac)",
          value: "\n",
        },
        {
          label: "CR LF (Windows)",
          value: "\r\n",
        },
      ],
      isBindProperty: false,
      isTriggerProperty: false,
    },
    {
      propertyName: "readOnly",
      label: "Read only",
      helpText: "Prevents editing",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "isRequired",
      label: "Required",
      helpText: "Makes input to the component mandatory",
      controlType: "SWITCH",
      isJSConvertible: true,
      isBindProperty: true,
      isTriggerProperty: false,
    },
    {
      propertyName: "customValidationRule",
      label: "Validation",
      helpText: "Sets a custom validation rule for the code editor",
      controlType: "INPUT_TEXT",
      placeholderText: "Enter validation rule",
      isBindProperty: true,
      isTriggerProperty: false,
      visibility: "SHOW_NAME",
      isRemovable: true,
      defaultValue: "",
    },
  ]),
  PanelCategory(PropsPanelCategory.EventHandlers, [
    getPopoverConfig(
      "onChange",
      "Triggers an action when the check state is changed",
    ),
  ]),
];

export default config;
