import { Dimension, Padding } from "@superblocksteam/shared";
import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import styled, { CSSProperties } from "styled-components";
import tinycolor from "tinycolor2";
import NotifiterTooltip from "components/ui/NotifierTooltip";
import { resizeSectionWidget } from "legacy/actions/widgetActions";
import { Layers } from "legacy/constants/Layers";
import {
  GridDefaults,
  WidgetHeightConstraintType,
  WidgetTypes,
  WidgetHeightModes,
  PAGE_WIDGET_ID,
  WIDGET_PADDING,
} from "legacy/constants/WidgetConstants";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import {
  getEditorReadOnly,
  getFlattenedCanvasWidget,
} from "legacy/selectors/editorSelectors";
import {
  getSelectedWidgetsIds,
  getSectionColumnChildIds,
  getFocusedWidget,
  modalOrSlideoutIsOpen,
} from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { getSectionColsForParentType } from "legacy/utils/WidgetPropsUtils";
import {
  generateClassName,
  generateSectionComponentId,
} from "legacy/utils/generators";
import AddSectionButton from "legacy/widgets/SectionWidget/AddSectionButton";
import WidgetHeightConstraintResizer from "legacy/widgets/SectionWidget/WidgetHeightConstraintResizer/WidgetHeightConstraintResizer";
import { isFixedHeight } from "legacy/widgets/base/sizing/dynamicLayoutUtils";
import { useAppDispatch, useAppSelector } from "store/helpers";
import { ContainerWidgetProps } from "../ContainerWidget";
import { ComponentBorder } from "../Shared/ComponentBorder";
import SelectableAndFocusableComponent from "../base/SelectableAndFocusableComponent";
import VisibilityContainer from "../base/VisibilityContainer";
import WidgetNameComponent from "../base/WidgetNameComponent";
import { generateBorderStyleObject } from "../base/generateBorderStyle";
import { generatePaddingStyleObject } from "../base/generatePaddingStyle";
import { ZERO_PADDING, getFlexStyles, isFillParent } from "../base/sizing";
import {
  useClearFillParent,
  useUpdateFillParentHeight,
} from "../base/sizing/dynamicLayoutHooks";
import { heightModeSupportsMinMax } from "../layoutProperties";
import { WidgetLayoutProps } from "../shared";
import SectionColumnComponent from "./SectionColumnComponent";
import {
  SectionColumnResizeGridLines,
  SectionColumnResizeLabels,
} from "./SectionColumnsResizeOverlay";
import { ExtensionInfo, SectionSizingProvider } from "./SectionSizingContext";
import { useSectionSizingContextSelector } from "./SectionSizingContextSelectors";
import SelectedWidgetConstraintResizers from "./WidgetConstraintResizer/SelectedWidgetConstraintResizers";
import { useWidgetConstraintResizers } from "./WidgetHeightConstraintResizer/constraintResizerHooks";
import type { AppState } from "store/types";

// ---------------------
// Styled Components
// ---------------------

const SectionContainer = styled.div`
  width: 100%;
  position: relative;

  &[data-show-section-dashed-borders="true"] {
    ::before {
      border: 1px dashed
        ${({ theme }) =>
          tinycolor(theme.colors.ACCENT_BLUE_500).setAlpha(0.5).toRgbString()};
    }
  }
`;

const SectionContainerInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
  height: 100%;

  &[data-stack-columns="true"] {
    flex-direction: column;
    justify-content: start;
  }
`;

// ---------------------
// Local utils & constants
// ---------------------

const ZERO_HEIGHT = Dimension.gridUnit(0);

const toPx = (
  dimension: Dimension<"gridUnit" | "fitContent" | "fillParent" | "px">,
) => {
  return Dimension.toPx(dimension, GridDefaults.DEFAULT_GRID_ROW_HEIGHT).value;
};

const getBorderRadiusStyles = ({
  parentIsModal,
  isFirstSection,
  isLastSection,
  themeBorderRadius,
}: {
  parentIsModal: boolean;
  isFirstSection: boolean;
  isLastSection: boolean;
  themeBorderRadius: number;
}) => {
  if (parentIsModal) {
    if (isFirstSection) {
      return {
        borderTopLeftRadius: themeBorderRadius,
        borderTopRightRadius: themeBorderRadius,
      };
    } else if (isLastSection) {
      return {
        borderBottomLeftRadius: themeBorderRadius,
        borderBottomRightRadius: themeBorderRadius,
      };
    }
  }
  return {};
};

// Exported for tests only
export const getSectionHeightPx = ({
  height,
  minHeight = ZERO_HEIGHT,
  maxHeight,
  useStateHeight,
  columnCanvasExtensionRows,
  resizingSectionHeightPx,
  stack,
  children,
  padding,
}: {
  height: Dimension<WidgetHeightModes>;
  minHeight?: Dimension<"gridUnit" | "px">;
  maxHeight?: Dimension<"gridUnit" | "px">;
  useStateHeight: false | WidgetHeightConstraintType;
  columnCanvasExtensionRows: ExtensionInfo | null;
  resizingSectionHeightPx: number;
  stack: boolean;
  children: WidgetLayoutProps[];
  padding?: Padding;
}): number => {
  const heightInPx = toPx(height);
  const minHeightInPx = toPx(minHeight);
  const maxHeightInPx = maxHeight ? toPx(maxHeight) : null;

  const getExtendedHeight = () => {
    if (columnCanvasExtensionRows !== null) {
      const columnExtensionPx = toPx(
        Dimension.gridUnit(columnCanvasExtensionRows?.rows || 0),
      );
      return Math.max(heightInPx, columnExtensionPx);
    }
    return heightInPx;
  };

  const adjustForFitContent = (currentHeight: number) => {
    let adjustedHeight = Math.max(currentHeight, minHeightInPx);
    if (maxHeightInPx !== null) {
      adjustedHeight = Math.min(adjustedHeight, maxHeightInPx);
    }
    return adjustedHeight;
  };

  const adjustForUseStateHeight = (currentHeight: number) => {
    if (useStateHeight === "minHeight") {
      return Math.max(currentHeight, resizingSectionHeightPx);
    } else if (useStateHeight === "maxHeight") {
      return Math.min(
        Math.max(currentHeight, minHeightInPx),
        resizingSectionHeightPx,
      );
    }
    return resizingSectionHeightPx;
  };

  let heightToUse = heightInPx;

  if (height.mode === "fitContent") {
    heightToUse = adjustForFitContent(getExtendedHeight());
  }

  if (useStateHeight) {
    heightToUse = adjustForUseStateHeight(heightToUse);
  }

  if (stack) {
    heightToUse = WIDGET_PADDING * 2;
    (children as WidgetLayoutProps[]).forEach((child) => {
      if (child.height) {
        if (columnCanvasExtensionRows?.dropTargetId === child.widgetId) {
          heightToUse +=
            columnCanvasExtensionRows.rows *
            GridDefaults.DEFAULT_GRID_ROW_HEIGHT;
        } else {
          heightToUse +=
            Dimension.toPx(child.height, GridDefaults.DEFAULT_GRID_ROW_HEIGHT)
              .value + Padding.y(child.padding).value;
        }
      }
    });
  }

  const paddingInPx = padding ? Padding.y(padding).value : 0;
  return heightToUse + paddingInPx;
};

// ---------------------
// Components
// ---------------------

export type SectionComponentProps = ContainerWidgetProps & {
  renderChildren: () => React.ReactNode[] | React.ReactNode;
  renderChildWidget: (childData: WidgetLayoutProps) => React.ReactNode;
};

const SectionComponent = (props: SectionComponentProps) => {
  const {
    widgetId,
    renderChildWidget,
    appMode,
    isLoading,
    children,
    parentColumnSpace,
    parentId,
    gridColumns,
    padding,
  } = props;
  const dispatch = useAppDispatch();
  const sectionPadding = padding ?? ZERO_PADDING;

  const {
    isResizingConstraint,
    setIsResizingConstraint,
    resizerMaxHeight,
    resizerMinHeight,
    resizingConstraintHeightPx,
    useStateHeight,
    setResizingConstraintHeightPx,
  } = useWidgetConstraintResizers(props);

  // ---------------------
  // Local State
  // ---------------------

  const [
    heightResizeDisabledMessageIsVisible,
    setHeightResizeDisabledMessageIsVisible,
  ] = useState(false);

  // ---------------------
  // Selectors
  // ---------------------
  const editorIsDisabled = useAppSelector(getEditorReadOnly);
  const generatedTheme = useAppSelector(selectGeneratedTheme);
  const selectedWidgetIds = useAppSelector((state: AppState) =>
    getSelectedWidgetsIds(state),
  );
  const sectionColumnChildIds = useAppSelector((state) =>
    getSectionColumnChildIds(state, widgetId),
  );
  const isSelected = selectedWidgetIds.includes(widgetId);

  const focusedWidget = useAppSelector((state: AppState) =>
    getFocusedWidget(state),
  );
  const parentWidget = useAppSelector((state: AppState) => {
    return getFlattenedCanvasWidget(state, parentId || "");
  });

  const isColumnResizing = useSectionSizingContextSelector(
    (context) => context.isColumnResizing,
  );

  const columnCanvasExtensionRows = useSectionSizingContextSelector(
    (context) => context.columnCanvasExtensionRowsInfo,
  );

  const isDraggingWidget = useAppSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isDragging,
  );
  const isResizingWidget = useAppSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.isResizing,
  );

  const currentDropTargetId = useAppSelector(
    (state: AppState) => state.legacy.ui.widgetDragResize.currentDropTarget,
  );

  const isModalOrSlideoutOpen = useAppSelector(modalOrSlideoutIsOpen);

  // ---------------------
  // Variables
  // ---------------------

  const columnIds = useMemo(
    () => children?.map((child) => child.widgetId) || [],
    [children],
  );

  const isFirstPageSection =
    parentWidget?.children?.[0] === widgetId &&
    [WidgetTypes.PAGE_WIDGET, WidgetTypes.SLIDEOUT_WIDGET].includes(
      parentWidget?.type as WidgetTypes,
    );

  const isFocused = focusedWidget?.widgetId === widgetId;

  const isDraggingOrResizingWidget = isDraggingWidget || isResizingWidget;

  const onlyColumnIsSelected =
    columnIds.length === 1 && columnIds[0] === selectedWidgetIds[0];

  const lastColumn = columnIds[columnIds.length - 1];

  const isLastColumnSelected = selectedWidgetIds.includes(lastColumn);

  const isLastColumnFocused = focusedWidget?.widgetId === lastColumn;

  const pageIsSelected = selectedWidgetIds.includes(parentWidget.widgetId);
  const pageIsFocused = focusedWidget?.widgetId === parentWidget.widgetId;
  let initialShownBreadcrumbs = 0;
  if (isFocused && isFirstPageSection && pageIsSelected) {
    initialShownBreadcrumbs = 1;
  }

  const showWidgetNameOverride = isLastColumnSelected && isFocused;

  const hideWidgetNameOverride =
    (isFirstPageSection && isSelected && isLastColumnFocused) ||
    (pageIsFocused && isSelected);

  const isOverColumnChildAsDropTarget =
    currentDropTargetId !== undefined &&
    columnIds.includes(currentDropTargetId);

  const isDraggingOrResizingOtherWidget =
    isDraggingOrResizingWidget && !isSelected;

  const isDraggingOrResizingSectionColumnChild =
    isDraggingOrResizingWidget &&
    sectionColumnChildIds.includes(selectedWidgetIds[0]);

  const childIds = children?.map((child) => child.widgetId) || [];

  const sectionColumnIsSelected = selectedWidgetIds.some((id) =>
    childIds.includes(id),
  );

  const sectionOrColumnIsSelected = sectionColumnIsSelected || isSelected;

  const sectionColumns = getSectionColsForParentType(parentWidget.type);
  const gridColumnsPerSectionColumn = (gridColumns || 0) / sectionColumns;

  const chilIndexOfSection = parentWidget.children?.indexOf(widgetId);

  const parentIsModal = parentWidget.type === WidgetTypes.MODAL_WIDGET;

  const parentIsModalOrSlideout =
    parentIsModal || parentWidget.type === WidgetTypes.SLIDEOUT_WIDGET;

  const parentHasTopPadding =
    parentWidget.padding?.top?.value != null &&
    parentWidget.padding?.top?.value > 0;

  const fillParent = isFillParent(props.height.mode);
  useClearFillParent(props.widgetId, fillParent, "height");

  const height = getSectionHeightPx({
    height: props.height,
    minHeight: props.minHeight,
    maxHeight: props.maxHeight,
    useStateHeight,
    columnCanvasExtensionRows,
    resizingSectionHeightPx: resizingConstraintHeightPx,
    stack: props.isStacked ?? false,
    children: children ?? [],
    padding: sectionPadding,
  });
  const computedHeightRef = useRef(height);

  useEffect(() => {
    if (!isDraggingOrResizingOtherWidget) {
      computedHeightRef.current = height;
    }
  }, [height, isDraggingOrResizingOtherWidget]);

  const showMinControl =
    heightModeSupportsMinMax(props.height.mode) && props.minHeight;
  const showMaxControl =
    heightModeSupportsMinMax(props.height.mode) && props.maxHeight;

  const borderStyle = useMemo(() => {
    return generateBorderStyleObject({
      border: props.border,
      // TODO: investigate why generatedTheme.container could be undefined
      fallbackBorderColor:
        generatedTheme.container?.section?.borderColor?.default,
    });
  }, [props.border, generatedTheme.container?.section?.borderColor?.default]);

  // ---------------------
  // Callbacks
  // ---------------------

  const handleResizeEnded = useCallback(
    (
      constraintType: WidgetHeightConstraintType,
      newHeight: Dimension<"gridUnit">,
    ) => {
      dispatch(resizeSectionWidget(props.widgetId, constraintType, newHeight));
    },
    [dispatch, props.widgetId],
  );

  const contentRef = useRef<HTMLDivElement>(null);

  // ---------------------
  // Returned markup
  // ---------------------
  const paddingStyles = useMemo(() => {
    return generatePaddingStyleObject(sectionPadding);
  }, [sectionPadding]);

  let sectionContent = (
    <>
      <VisibilityContainer
        widgetId={widgetId}
        isVisible={props.isVisible ?? true}
      >
        <SectionContainerInner
          id={generateSectionComponentId(widgetId)}
          data-test="section-component-inner"
          data-stack-columns={Boolean(props.isStacked ?? false)}
          ref={contentRef}
          style={paddingStyles}
        >
          <ComponentBorder borderStyle={borderStyle} />
          <>
            {children?.map((childWidget: WidgetLayoutProps, index: number) => {
              return (
                <SectionColumnComponent
                  key={childWidget.widgetId}
                  {...childWidget}
                  columnRightWidget={children[index + 1]}
                  columnIndex={index}
                  isLastColumn={index === children.length - 1}
                  renderChildWidget={renderChildWidget}
                  appMode={appMode}
                  isLoading={isLoading}
                  isFirstPageSection={isFirstPageSection}
                  gridColumnsPerSectionColumn={gridColumnsPerSectionColumn}
                  sectionIsSelected={isSelected}
                  sectionIsFocused={isFocused}
                  pageIsFocused={pageIsFocused}
                  sectionOrColumnIsSelected={sectionOrColumnIsSelected}
                  parentIsModalOrSlideout={parentIsModalOrSlideout}
                  isDraggingOrResizingSectionColumnChild={
                    isDraggingOrResizingSectionColumnChild
                  }
                  isOverColumnChildAsDropTarget={isOverColumnChildAsDropTarget}
                  isStacked={props.isStacked ?? false}
                />
              );
            })}
          </>
          {appMode === APP_MODE.EDIT && isColumnResizing && (
            <>
              <SectionColumnResizeGridLines sectionColumns={sectionColumns} />
              <SectionColumnResizeLabels
                sectionColumns={sectionColumns}
                childWidgets={children}
                gridColumnsPerSectionColumn={gridColumnsPerSectionColumn}
              />
            </>
          )}
        </SectionContainerInner>
      </VisibilityContainer>
      {appMode === APP_MODE.EDIT && (
        <>
          {(isFocused || isSelected) && (
            <>
              <WidgetNameComponent
                {...props}
                showNameOverride={showWidgetNameOverride}
                hideNameOverride={hideWidgetNameOverride}
                hasInvalidProps={false}
                errorMessage={""}
                widgetNamePosition={isFirstPageSection ? "inset" : "top"}
                initialShownBreadcrumbs={initialShownBreadcrumbs}
                disableHoverInteraction={true}
              />
              {isSelected && !editorIsDisabled && (
                <>
                  <PaddingOverlay
                    widgetId={widgetId}
                    padding={sectionPadding}
                    parentId={parentId}
                    appMode={props.appMode}
                  />
                  <WidgetHeightConstraintResizer
                    height={
                      useStateHeight === "height"
                        ? Dimension.px(resizingConstraintHeightPx)
                        : props.height
                    }
                    constraintLabel={undefined}
                    constraintType="height"
                    isResizing={
                      isResizingConstraint && useStateHeight === "height"
                    }
                    setIsResizingConstraint={setIsResizingConstraint}
                    setResizingHeight={setResizingConstraintHeightPx}
                    onResizeEnded={handleResizeEnded}
                    disabled={!isFixedHeight(props.height.mode)}
                    showDisabledMessage={() => {
                      setHeightResizeDisabledMessageIsVisible(true);
                    }}
                    paddingOffsetPx={Padding.y(sectionPadding).value}
                  />
                  {showMinControl && !editorIsDisabled && (
                    <WidgetHeightConstraintResizer
                      height={resizerMinHeight}
                      constraintLabel="Min"
                      constraintType="minHeight"
                      isResizing={
                        isResizingConstraint && useStateHeight === "minHeight"
                      }
                      setIsResizingConstraint={setIsResizingConstraint}
                      setResizingHeight={setResizingConstraintHeightPx}
                      paddingOffsetPx={Padding.y(sectionPadding).value}
                      onResizeEnded={handleResizeEnded}
                    />
                  )}
                  {showMaxControl && !editorIsDisabled && (
                    <WidgetHeightConstraintResizer
                      height={resizerMaxHeight}
                      constraintLabel="Max"
                      constraintType="maxHeight"
                      isResizing={
                        isResizingConstraint && useStateHeight === "maxHeight"
                      }
                      setIsResizingConstraint={setIsResizingConstraint}
                      setResizingHeight={setResizingConstraintHeightPx}
                      paddingOffsetPx={Padding.y(sectionPadding).value}
                      onResizeEnded={handleResizeEnded}
                    />
                  )}
                </>
              )}
            </>
          )}
          <SelectedWidgetConstraintResizers
            sectionWidgetId={widgetId}
            sectionContentRef={contentRef}
          />
        </>
      )}
    </>
  );

  if (appMode === APP_MODE.EDIT) {
    sectionContent = (
      <SelectableAndFocusableComponent
        widget={{
          ...props,
          appMode,
          isLoading,
        }}
        // it's important we disable this component instead of directly rendering the contents coniditionally, since this causes a remount
        disabled={
          (isModalOrSlideoutOpen && props.parentId === PAGE_WIDGET_ID) ||
          isDraggingOrResizingOtherWidget ||
          isColumnResizing
        }
      >
        {sectionContent}
        {!isDraggingOrResizingWidget &&
          !isResizingConstraint &&
          (isSelected || isFocused || onlyColumnIsSelected) && (
            <AddSectionButton
              sectionWidgetId={props.widgetId}
              placement="above"
              isTopEdge={isFirstPageSection && !parentHasTopPadding}
              padding={Padding.x(sectionPadding).value ?? 0}
            />
          )}
        {!isDraggingOrResizingWidget &&
          !isResizingConstraint &&
          (isSelected || isFocused || onlyColumnIsSelected) && (
            <AddSectionButton
              sectionWidgetId={props.widgetId}
              padding={Padding.x(sectionPadding).value ?? 0}
              placement="below"
            />
          )}
        <NotifiterTooltip
          isVisible={heightResizeDisabledMessageIsVisible}
          setIsVisible={setHeightResizeDisabledMessageIsVisible}
          messageHideDelayMs={6000}
          containerStyle={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            maxWidth: "90%",
            transform: "translate(-50%, 50%)",
            zIndex: Layers.addSectionButton + 1,
          }}
        >
          To resize manually change {props.widgetName} height to pixels
        </NotifiterTooltip>
      </SelectableAndFocusableComponent>
    );
  }

  const numChildren = (parentWidget.children || []).length;
  const style: CSSProperties = useMemo(
    () => ({
      // we switched the fallback BG color to appBackground for page sections and transparent for sections/modals
      // this ensures backwards compatibility for existing modals/slideouts
      backgroundColor: parentIsModalOrSlideout
        ? props.backgroundColor ?? "transparent"
        : props.backgroundColor,
      width: Dimension.toPx(props.width, parentColumnSpace).value || "100%",
      height,
      ...getFlexStyles({
        height: props.height,
        minHeight: props.minHeight,
        maxHeight: props.maxHeight,
        width: props.width,
        parentColumnSpace,
        parentDirection: "column",
        isDraggingOrResizingOtherWidget,
        computedHeight: computedHeightRef.current,
        margin: props.margin,
      }),
      ...getBorderRadiusStyles({
        parentIsModal,
        isFirstSection: chilIndexOfSection === 0,
        isLastSection: chilIndexOfSection === numChildren - 1,
        themeBorderRadius: generatedTheme.borderRadius.value,
      }),
    }),
    [
      parentIsModalOrSlideout,
      props.backgroundColor,
      props.width,
      props.height,
      props.minHeight,
      props.maxHeight,
      props.margin,
      parentColumnSpace,
      height,
      isDraggingOrResizingOtherWidget,
      parentIsModal,
      chilIndexOfSection,
      numChildren,
      generatedTheme.borderRadius.value,
    ],
  );

  const sectionContainerProps = {
    className: `${generateClassName(widgetId)} ${CLASS_NAMES.SECTION}`,
    "data-test": `section-component-${props.widgetName}`,
    "data-show-section-dashed-borders": Boolean(
      appMode === APP_MODE.EDIT &&
        (isDraggingOrResizingSectionColumnChild ||
          isOverColumnChildAsDropTarget),
    ),
    padding: sectionPadding,
  };

  if (fillParent) {
    return (
      <SectionContainerWithFillParent
        widgetId={widgetId}
        style={style}
        {...sectionContainerProps}
      >
        {sectionContent}
      </SectionContainerWithFillParent>
    );
  } else {
    return (
      <SectionContainer id={widgetId} style={style} {...sectionContainerProps}>
        {sectionContent}
      </SectionContainer>
    );
  }
};

const SectionContainerWithFillParent = (
  props: {
    widgetId: string;
    className: string;
    style: React.CSSProperties;
    children: React.ReactNode;
    padding: Padding;
  } & Record<string, unknown>,
) => {
  const { widgetId, className, style, padding, ...rest } = props;
  const ref = useUpdateFillParentHeight(
    props.widgetId,
    Padding.y(padding)?.value,
    true,
  );

  return (
    <SectionContainer
      id={widgetId}
      className={className}
      style={style}
      ref={ref}
      {...rest}
    >
      <div style={{ position: "absolute", inset: 0 }}>{props.children}</div>
    </SectionContainer>
  );
};

const ConnectedComponent = (props: SectionComponentProps) => {
  const { children, ...providerProps } = props;
  return (
    <SectionSizingProvider
      {...providerProps}
      columns={children as WidgetLayoutProps[]}
    >
      <SectionComponent {...props} />
    </SectionSizingProvider>
  );
};

export default ConnectedComponent;
