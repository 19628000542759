import { CloseOutlined } from "@ant-design/icons";
import { Dimension, Padding } from "@superblocksteam/shared";
import { Button, Drawer } from "antd";
import React, { ReactNode, useMemo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Layers } from "legacy/constants/Layers";
import {
  SLIDEOUT_CONTAINER_ID,
  SlideoutSize,
  SLIDEOUT_GAP,
  SLIDEOUT_WIDTH_PRESETS,
} from "legacy/constants/WidgetConstants";
import EmptySpaceOverLay from "legacy/pages/Editor/CanvasArenas/EmptySpaceOverLay";
import PaddingOverlay from "legacy/pages/Editor/CanvasArenas/PaddingOverlay";
import { APP_MODE } from "legacy/reducers/types";
import { getFocusedWidget } from "legacy/selectors/sagaSelectors";
import { CLASS_NAMES } from "legacy/themes/classnames";
import { WidgetProps } from "../BaseWidget";
import { ScrollContainer } from "../Shared/ScrollContainer";
import SelectableAndFocusableComponent from "../base/SelectableAndFocusableComponent";
import WidgetNameComponent from "../base/WidgetNameComponent";
import {
  ComputeStyleProps,
  useComputeWidgetStyles,
} from "../base/generateWidgetStyles";
import { ZERO_PADDING } from "../base/sizing";

type SlideoutComponentProps = Omit<WidgetProps, "children"> & {
  isOpen: boolean;
  isVisible: boolean;
  size: SlideoutSize;
  isClosing: boolean;
  onClose: (e: any) => void;
  afterVisibleChange: (visible: boolean) => void;
  children: ReactNode;
  className?: string;
  canOutsideClickClose: boolean;
  hasBackdrop: boolean;
  appMode: APP_MODE;
  firstSectionId?: string;
  childrenWidgets?: WidgetProps[];
  styleProps: ComputeStyleProps;
  internalWidth?: Dimension<"px">;
};

const getParent = () => {
  return (
    (document.querySelector(
      `[data-superblocks='${SLIDEOUT_CONTAINER_ID}']`,
    ) as HTMLElement) || document.body
  );
};

const CloseButtonStyled = styled(Button)`
  position: fixed;
  right: 20px;
  z-index: ${Layers.closeButton};
  /* make the button circular and center its contents */
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    /* make the image a block element so that it observes vertical alignment rules better */
    display: block;
  }
`;

const CLOSE_BUTTON_MIN_OFFSET = 4;

const CloseButton = ({
  onClose,
  padding,
}: {
  onClose: (e: any) => void;
  padding: Padding;
}) => {
  const positionStyles = useMemo(() => {
    const top = Math.max(padding.top?.value ?? 0, CLOSE_BUTTON_MIN_OFFSET);
    const right = Math.max(padding.right?.value ?? 0, CLOSE_BUTTON_MIN_OFFSET);
    return {
      top,
      right,
    };
  }, [padding]);

  return (
    <CloseButtonStyled
      type="text"
      aria-label="Close"
      data-test="slideout-close"
      onClick={onClose}
      style={positionStyles}
    >
      <CloseOutlined className={CLASS_NAMES.CLOSE_ICON} />
    </CloseButtonStyled>
  );
};

const SlideoutComponent = (props: SlideoutComponentProps) => {
  // We are controlling the visible change hook on our own because
  // the transform styling overrides will break the native antd
  // drawer afterVisibleChange hook.
  const afterVisibleChange = useRef(props.afterVisibleChange);
  const isDrawerOpen = useMemo(() => {
    afterVisibleChange.current = props.afterVisibleChange;
    if (props.isClosing) {
      return props.isOpen;
    } else {
      return props.isVisible;
    }
  }, [
    props.afterVisibleChange,
    props.isClosing,
    props.isOpen,
    props.isVisible,
  ]);

  useEffect(() => {
    afterVisibleChange.current(isDrawerOpen);
  }, [isDrawerOpen]);

  const slideoutPadding = props.styleProps?.padding ?? ZERO_PADDING;

  const [slideoutWidth, slideoutWidthWithPadding] = useMemo(() => {
    const slideoutWidth =
      SLIDEOUT_WIDTH_PRESETS[props.size as SlideoutSize] ||
      SLIDEOUT_WIDTH_PRESETS[SlideoutSize.MEDIUM];

    const slideoutXPaddingPx = Padding.x(slideoutPadding)?.value ?? 0;
    // we account for padding but not border, because the border is rendered on the drawer
    if (props.internalWidth?.value) {
      return [slideoutWidth, props.internalWidth.value + slideoutXPaddingPx];
    }
    return [slideoutWidth, slideoutWidth];
  }, [props.size, props.internalWidth, slideoutPadding]);

  const focusedWidget = useSelector(getFocusedWidget);
  const isFirstSectionFocused =
    focusedWidget?.widgetId === props.firstSectionId;
  const isFocused = focusedWidget?.widgetId === props.widgetId;

  let drawerContent;
  const { children, ...rest } = props;
  const { backgroundStyle, borderStyle, paddingStyle } = useComputeWidgetStyles(
    props.styleProps ?? {},
  );

  if (props.appMode === APP_MODE.EDIT) {
    drawerContent = (
      <>
        <SelectableAndFocusableComponent
          widget={rest}
          parentRectInset={-1}
          focusedRectInset={0}
          selectedRectInset={0}
        >
          <WidgetNameComponent
            {...rest}
            hasInvalidProps={false}
            errorMessage={""}
            widgetNamePosition="inset"
            hideNameOverride={isFirstSectionFocused}
          />
          <div
            style={{
              width:
                slideoutWidth === "100%" ||
                typeof slideoutWidthWithPadding !== "number"
                  ? slideoutWidthWithPadding
                  : slideoutWidthWithPadding + 2, // adding 2px to avoid section border being clipped
              paddingLeft: 1, // adding 1px on left side for section border to show
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflowY: "visible",
              overflowX: "clip", // clip when max width draggable label is overflowed
              position: "relative",
              ...paddingStyle,
            }}
          >
            <PaddingOverlay
              widgetId={props.widgetId}
              padding={slideoutPadding}
              appMode={props.appMode}
            />
            <CloseButton onClose={props.onClose} padding={slideoutPadding} />
            {children}
            <EmptySpaceOverLay
              isFocused={isFocused}
              childrenWidgets={props.childrenWidgets}
            />
          </div>
        </SelectableAndFocusableComponent>
      </>
    );
  } else {
    drawerContent = (
      <div
        style={{
          width: slideoutWidthWithPadding,
          display: "flex",
          flexDirection: "column",
          position: "relative",
          height: "100%",
          ...paddingStyle,
        }}
      >
        <CloseButton onClose={props.onClose} padding={slideoutPadding} />
        {props.children}
      </div>
    );
  }

  return (
    <Drawer
      width={
        props.appMode === APP_MODE.EDIT && typeof slideoutWidth === "number"
          ? slideoutWidth + 2 // adding 1px on each side for section border to show
          : slideoutWidth
      }
      open={isDrawerOpen}
      onClose={(e: any) => {
        props.onClose(e);
      }}
      maskClosable={props.canOutsideClickClose}
      keyboard={props.canOutsideClickClose}
      contentWrapperStyle={{
        maxWidth: `calc(100% - ${SLIDEOUT_GAP}px)`,
        borderRadius: borderStyle.borderRadius,
      }}
      getContainer={getParent}
      style={{ position: "absolute" }}
      bodyStyle={{
        position: "relative",
        padding: 0,
      }}
      zIndex={Layers.drawer}
      closable={false /* use our own close button */}
      maskStyle={!props.hasBackdrop ? { backgroundColor: "transparent" } : {}}
      data-test="slideout-component"
      className={CLASS_NAMES.SLIDEOUT}
      id={`slideout-component-${props.widgetId}`}
      drawerStyle={{
        ...borderStyle,
        ...backgroundStyle,
      }}
    >
      <ScrollContainer
        scroll={true}
        style={{ width: "100%", height: "100%" }}
        scrollAreaId={`slideout-scroll-area-${props.widgetId}`}
      >
        {drawerContent}
      </ScrollContainer>
    </Drawer>
  );
};

export default SlideoutComponent;
